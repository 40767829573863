@import '../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  display: flex;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 100px;
  }
}

.imagesWrapper {
  display: none;
  flex-direction: row;
  justify-content: center;
  gap: 24px;

  margin: 24px 0 0;
  position: relative;

  @media (--viewportMedium) {
    display: flex;
    margin: 0;
  }
}

.imagesWrapperSurroundings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;

  margin: 24px 0 0;
  position: relative;
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    width: 80%;
    margin: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    padding: 36px 0 0 0;
  }

  @media (--viewportLarge) {
    padding: 36px 0 0 0;
  }
}

.threeToTwoWrapperSurroundings {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 36px 0 0 0;
  }

  @media (--viewportLarge) {
    padding: 36px 0 0 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }
}

.aspectWrapperSurroundings {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: calc(100% * (2 / 3));
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: var(--borderRadius);

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.rootForImageSurroundings {
/* Layout - image will take space defined by aspect ratio wrapper */
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100%;
border-radius: var(--borderRadius);

height: 100%;
object-fit: cover;
transition: var(--transitionStyleButton);

@media (--viewportMedium) {
  &:hover {
    transform: scale(1.005);
    box-shadow: var(--boxShadowListingCard);
  }
}
}

.actionBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100%);
  margin: 24px 0 0;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 8px;
  right: 4px;
  margin: 0;
  padding: 8px;

  /* Colors */
  background-color: var(--attentionColor);
  color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--attentionColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    bottom: 19px;
    right: 24px;
    margin: 0;
    padding: 12px 24px 12px 24px;
  }
}

.viewText {
  display: none;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    display: block;
  }
}

.viewIcon {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  background-color: var(--matterColorDark);
  width: 100%;
  height: 100%;
}

.mobileImageCarousel {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.contentContainer {
  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    padding: 0 100px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    padding: 0 148px;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 24px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
    padding-right: 24px;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 36px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);
    border-radius: 20px;

    padding: 32px 48px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
    box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    margin-bottom: 80px;
  }
}

.type {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 32px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.typeIcon {
  width: 24px;
  height: 24px;
}

.typeId {
  color: var(--successColor);
  font-family: var(--fontFamilyRegular);
  line-height: 24px;
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 32px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  composes: subtitle from global;
  color: var(--inputColor);
  width: 100%;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 32px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 80px;
  }
}

.descriptionTitle {
  /* Font */
  composes: subtitle from global;
  color: var(--matterColorDark);
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionWifi {
  padding: 0 32px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 80px;
  }
}

.wifiTitle {
  /* Font */
  composes: subtitle from global;
  color: var(--matterColorDark);
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 16px;

  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.1);

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 24px;
  }
}

.wifiIcon {
  padding: 0 32px 24px;
  width: calc(50%);

  @media (--viewportMedium) {
    padding: 0 32px;
    width: fit-content
  }
}

.wifiInfo {
  padding: 0 0 24px;
  width: calc(50%);

  @media (--viewportMedium) {
    padding: 0 32px;
    width: fit-content
  }
}

.wifiName {
  color: var(--matterColorDark);
  margin: 8px 0;
}

.wifiSpeed {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: var(--successColor);
  margin: 8px 0;
  white-space: nowrap;
}

.wifiDescription {
  display: flex;
  flex-direction: row;
  /* Font */
  color: var(--matterColorDark);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;

  margin-top: 0;
  margin-bottom: 0;
  padding: 24px 0 0;

  position: relative;

  @media (--viewportMedium) {
    padding: 0 32px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.wifiDescription::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 100%;
  border-top: 1px solid var(--inputColor);

  @media (--viewportMedium) {
    left: 0;
    top: -12px;
    height: 80px;
    width: 50%;
    border-top: none;
    border-left: 1px solid var(--inputColor);
  }
}

.sectionFeatures {
  padding: 0 32px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 80px;
  }
}

.workspace {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  width: 100%;
}

.livingItem,
.item {
  background: var(--attentionColor);
  border-radius: 5px;
  color: var(--matterColorBright);
  font-size: 12px;
  line-height: 18px;

  text-align: center;
  padding: 4px 12px!important;

  @media (--viewportMedium) {
    font-size: 16px;
  line-height: 26px;
  }
}

.livingItem {
  background: var(--lightSecondary);
  color: var(--matterColorBright);
}

.featuresTitle {
  /* Font */
  composes: subtitle from global;
  color: var(--matterColorDark);
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.amenities {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 12px;
  margin-top: 8px;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.amenity {
  display: flex;
  flex-direction: row;
  color: #5A5A5A;
  font-size: 16px;

  @media (--viewportMedium) {
    width: fit-content;
    margin-right: 24px;
  }
}

.icon {
  width: 30px;
  height: 30px;

  @media (--viewportMedium) {
    width: 60px;
    height: 60px;
  }
}

.overview {
  color: var(--attentionColor);
  font-size: 14px;
  line-height: 38px;

  margin: auto 0 auto 8px;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.description {
  color: #5A5A5A;
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 32px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: subtitle from global;
  color: var(--matterColorDark);
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionReviews {
  padding: 0 32px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 32px;
  }
}

.reviewsHeading {
  composes: subtitle from global;
  color: var(--matterColorDark);
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.reviewsResume {
  display: flex;
  flex-direction: column;
  box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

  padding: 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.resume {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--inputColor);

  @media (--viewportMedium) {
    width: 33.33%;
    padding-right: 24px;
    padding-bottom: 0;
    border-right: 1px solid var(--inputColor);
    border-bottom: none;
  }
}

.resumeHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  margin-bottom: 16px;
}

.resumeText {
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 24px;
  height: fit-content;
  margin: auto 0;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 26px;
  }
}

.resumeTextSmall {
  color: var(--inputColor);
  font-size: 12px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 24px;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: flex;

  @media (--viewportMedium) {
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
  margin: auto 0;
}

.reviewRatingStar {
  height: 14px;
  width: 14px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.resumeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  margin-bottom: 8px;
}

.progress {
  height: 4px;
  width: 50%;
  background-color: var(--inputColor);
  border-radius: 2px;
  margin-top: 8px;
}

.progressFill {
  height: 4px;
  width: 100%;
  background-color: #5A5A5A;
  border-radius: 2px;
}

.lastReview {
  padding-top: 24px;

  @media (--viewportMedium) {
    width: 66.66%;
    padding-left: 24px;
    padding-top: 0;
  }
}

.lastReviewItem li div {
  border-bottom: none;
}

.sectionHost {
  position: relative;
  padding: 0 32px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 80px;
  }
}

.yourHostHeading {
  /* Font */
  composes: subtitle from global;
  color: var(--matterColorDark);
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(50vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  border-radius: var(--borderRadius);
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}